import React from 'react';

function Footer() {
  return (
    <footer className=" py-4">
      <div className="container mx-auto px-4">
        <p className="text-center text-gray-600">© 2023 Jumbo Idli. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;

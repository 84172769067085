import React from 'react';
import logo from './logo.png';

function MenuBar() {
  return (
    <nav className="bg-white fixed top-0 w-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-12 w-12 rounded-full" src={logo} alt="Logo" />
            </div>
            <div className="ml-2 md:ml-4">
              <h3 className="text-2xl md:text-3xl font-extrabold text-green-800">Ganesha Jumbo Idli</h3>
              <p className="text-xs md:text-sm text-gray-600">Since 1990 !</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MenuBar;

import React from 'react';
import LandingPagetwo from './landingpagetwo';
import Chatbot from './chatBot/chatbot';
import MenuBar from './menuBar';
import LandingPage from './landingpage';
import LandingPage3 from './landingpage3';
import AboutUs from './aboutus';
import ContactUs from './contactus';
import Footer from './footter';

function App() {
  return (
    <div className="relative min-h-screen bg-white">
      <MenuBar />
      <div className="py-8">
        <LandingPage />
        <LandingPagetwo />
        <LandingPage3 />
        <AboutUs />
        <ContactUs />
      </div>
      <Footer />
      <Chatbot />
    </div>
  );
}

export default App;

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';

function ContactUs() {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post('/send-email', values);
      console.log(response.data);
      resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4 text-green-800 text-center">Contact Us</h1>
      <div className="max-w-md mx-auto">
        <Formik
          initialValues={{
            name: '',
            email: '',
            message: ''
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = 'Name is required';
            }
            if (!values.email) {
              errors.email = 'Email is required';
            } else if (!/^\S+@\S+$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            if (!values.message) {
              errors.message = 'Message is required';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-4">
                <label className="block text-lg font-medium" htmlFor="name">
                  Name
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
                <ErrorMessage name="name" component="p" className="text-red-500" />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-medium" htmlFor="email">
                  Email
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
                <ErrorMessage name="email" component="p" className="text-red-500" />
              </div>
              <div className="mb-4">
                <label className="block text-lg font-medium" htmlFor="message">
                  Message
                </label>
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  className="border border-gray-300 rounded-md p-2 w-full"
                  rows="4"
                />
                <ErrorMessage name="message" component="p" className="text-red-500" />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send'}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mt-8">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56837117.530947395!2d-63.43782111376398!3d29.601873714062382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c140de0a5175%3A0x1a10130f35173bc9!2sJumbo%20Idli!5e0!3m2!1sen!2sin!4v1686019412844!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;

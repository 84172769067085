import React from 'react';
import { motion } from 'framer-motion';
import fermentationImage from './how-to-make-perfect-idli-batter--2--9-187651-YNqD7EgBjrT15W92.avif';

function LandingPage2() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center h-[80%] text-green-800 my-10 mx-4">
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-1/2 md:mr-8"
      >
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          src={fermentationImage}
          alt="Fermentation"
          className="rounded-[90%] w-full h-auto"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-1/2"
      >
        <h1 className="text-4xl font-bold font-stylish text-center md:text-left mb-4">
          Authentic Fermentation Process
        </h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="text-lg mt-4 text-center md:text-left"
        >
          At Jumbo Idli, we pride ourselves on our authentic fermentation process, which plays a vital role in creating idlis with the perfect texture and flavor. Our skilled chefs combine a precise blend of rice and lentils with water, allowing the batter to naturally ferment over several hours. This natural fermentation process not only enhances the taste but also improves the nutritional value of our idlis. The result is light, fluffy idlis with a unique tangy taste and a soft, spongy texture that melts in your mouth. Our commitment to maintaining high-quality standards ensures that every bite of our idlis reflects the dedication and passion we put into our authentic fermentation process.
        </motion.p>
      </motion.div>
    </div>
  );
}

export default LandingPage2;
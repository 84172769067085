import React from 'react';

function Chatbot() {
  const handleWhatsAppRedirect = () => {
    window.open('https://api.whatsapp.com/send?phone=91721 69606&text=Notify%20me%20for%20Hot%20idlies');
  };

  return (
    <div className="fixed bottom-0 right-0 h-28 bg-transparent">
      <img
        onClick={handleWhatsAppRedirect}
        src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-mobile-software-icon-png-image_6315991.png"
        alt="WhatsApp Logo"
        className="fixed bottom-6 right-8  font-bold  bg-transparent hover:animate-bounce"
        style={{ width: '70px', height: '70px' }}
      />
    </div>
  );
}

export default Chatbot;

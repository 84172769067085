import React from 'react';

function AboutUs() {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4 text-green-800  ml-[40%]">About Us</h1>
      <p className="text-lg mb-6">
        Welcome to Jumbo Idli, where we have been delighting our customers with our delicious jumbo-sized idlis since 1990. We are passionate about creating idlis that not only taste amazing but also promote a healthy lifestyle. That's why we use only the finest natural raw materials in our idli-making process, ensuring that each bite is filled with wholesome goodness.
      </p>
      <p className="text-lg mb-6">
        Our idlis are crafted with love and care to provide a delightful culinary experience. We take pride in being a 100% vegetarian establishment, making our idlis suitable for all, including Jains. We understand the importance of dietary preferences and strive to offer a diverse range of options to cater to everyone's needs.
      </p>
   
    </div>
  );
}

export default AboutUs;
import React from 'react';
import { motion } from 'framer-motion';
import landingImage from './image.jpg';

function LandingPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-col md:flex-row items-center w-full">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className='p-7 text-center md:text-left'
        >
          <h1 className="text-4xl md:text-6xl font-bold text-green-800 mb-4">
            Delicious and Healthy Jumbo Idli Since 1990!
          </h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
            className="text-lg mt-4 text-black"
          >
            Enjoy Vegan Jumbo Idlis made with Natural Ingredients in Navi Mumbai
          </motion.p>
          <motion.button
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 1 }}
            className="bg-green-800 text-white py-2 px-4 mt-4 rounded"
          >
            Contact Us
          </motion.button>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="mt-8 md:mt-0"
        >
          <motion.img
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
            src={landingImage}
            alt="Landing"
            className="rounded-[90%] max-w-full h-auto"
          />
        </motion.div>
      </div>
    </div>
  );
}

export default LandingPage;

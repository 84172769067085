import React from 'react';
import zomatoosiwgy from './zomato-and-swiggy-are-best-food-delivery-apps-in-the-world-mP4Zz9033gHx29Le.avif';
import zomatoLogo from './zomato-logo.png';
import swiggyLogo from './Swiggy-logo.png';

function LandingPage3() {
  return (
    <div className="flex flex-col items-center justify-center my-10 px-4">
 
      <div className="w-full lg:w-2/3 xl:w-1/2 mt-8">
        <div className="container mx-auto flex flex-col items-center p-4">
          <h1 className="text-4xl font-bold mb-4 text-green-800 text-center">Let's Not Forget</h1>
          <h2 className="text-2xl font-bold mb-4 text-green-800 text-center">Order Online</h2>
          <p className="text-lg mb-6 text-center">
            In addition to our dine-in option, you can also order our jumbo idlis online and enjoy them from the comfort of your home. Indulge in the authentic flavors and fluffy texture of our jumbo idlis, made with love and care using the finest ingredients. Take advantage of our special offers and promotions when you order online, ensuring a delightful experience at an incredible value. With our efficient delivery service, your order will be promptly delivered to your doorstep, ensuring a hassle-free and convenient dining experience. Don't miss out on the opportunity to enjoy our mouthwatering jumbo idlis without leaving the comfort of your home. Place your order now and experience the goodness of Ganesha Jumbo Idli delivered right to your door.
          </p>
          <div className="flex items-center justify-center space-x-4">
            <a href="http://zoma.to/r/19143432" target="_blank" rel="noopener noreferrer">
              <img src={zomatoLogo} alt="Zomato" className="w-20 h-20 cursor-pointer" />
            </a>
            <a href="https://www.swiggy.com/restaurants/ganesha-jumbo-idli-vashi-turbhe-mumbai-190975" target="_blank" rel="noopener noreferrer">
              <img src={swiggyLogo} alt="Swiggy" className="w-30 h-20 cursor-pointer" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage3;
